
import React, { Suspense, useState } from "react";
import PathContext from './context/path';
import Preloader from './layers/Preloader';
import PreloaderEn from './layersEn/Preloader';
const App = React.lazy(() => import('./App'));
const AppEn = React.lazy(() => import('./AppEn'));


const Wrapper = () => {
  const path = '.';
  const [isLoaded, setLoaded] = useState(false);
  const [video, setVideo] = useState([]);
  
  const updateLoaded = (bool)=>{
    return setLoaded(bool);
  }
  const updateVideo = (bool)=>{
    return setVideo(bool);
  }
 

  let appComp = null;
  if(isLoaded){
    appComp = <Suspense>
        
    {/* <App video={video}/> */}
    <AppEn video={video}/>
  </Suspense>
  }
  // const preloader = <Preloader isLoaded={isLoaded} updateLoaded={updateLoaded} updateVideo={updateVideo}/>;
  const preloader = <PreloaderEn isLoaded={isLoaded} updateLoaded={updateLoaded} updateVideo={updateVideo}/>;
  return (
    <PathContext.Provider value={path}>
      {preloader}
      {appComp}
      
    </PathContext.Provider>
  );

}


export default Wrapper;
