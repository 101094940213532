import { createRoot } from 'react-dom/client';

import './index.scss';

import './fonts/stylesheet.css';
import Wrapper from './Wrapper';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Wrapper/>);

document.querySelector('body').style.opacity = 1;
