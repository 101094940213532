import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Preloader.module.scss";
import PathContext from "../../context/path";
const Preloader = (props) => {
  const { isLoaded, updateLoaded, updateVideo } = props;
  const [hide, setHide] = useState(false);
  const [percent, setPercent] = useState(0);
  const [localVideoSrc1, setLocalVideoSrc1] = useState(null);
  const [localVideoSrc2, setLocalVideoSrc2] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const path = useContext(PathContext);
  const refWrap = useRef(null);
  const refSeq = useRef([
    'Falling-poster.jpg',
    'Turn-poster.jpg',
    'sequence1/1.png',
    'sequence1/2.png',
    'sequence1/3.png',
    'sequence1/4.png',
    'sequence1/5.png',
    'sequence1/6.png',
    'sequence1/7.png',
    'sequence1/8.png',
    'sequence1/9.png',
    'sequence1/10.png',
    'sequence1/11.png',
    'sequence1/12.png',
    'sequence1/13.png',
    'sequence1/14.png',
    'sequence1/15.png',
    'sequence1/16.png',
    'sequence1/17.png',
    'sequence1/18.png',
    'sequence1/19.png',
    'sequence1/20.png',
    'sequence1/21.png',
    'sequence1/22.png',
    'sequence1/23.png',
    'sequence1/24.png',
    'sequence1/25.png',
    'sequence1/26.png',
    'sequence1/27.png',
    'sequence1/28.png',
    'sequence1/29.png',
    'sequence1/30.png',
    'sequence1/31.png',
    'sequence1/32.png',
    'sequence1/33.png',
    'sequence1/34.png',
    'sequence1/35.png',
    'sequence1/36.png',
    'sequence1/37.png',
    'sequence1/38.png',
    'sequence1/39.png',
    'sequence1/40.png',
    'sequence1/41.png',
    'sequence1/42.png',
    'sequence1/43.png',
    'sequence1/44.png',
    'sequence1/45.png',
    'sequence1/46.png',
    'sequence1/47.png',
    'sequence1/48.png',
    'sequence1/49.png',
    'sequence1/50.png',
    'sequence2/1.png',
    'sequence2/2.png',
    'sequence2/3.png',
    'sequence2/4.png',
    'sequence2/5.png',
    'sequence2/6.png',
    'sequence2/7.png',
    'sequence2/8.png',
    'sequence2/9.png',
    'sequence2/10.png',
    'sequence2/11.png',
    'sequence2/12.png',
    'sequence2/13.png',
    'sequence2/14.png',
    'sequence2/15.png',
    'sequence2/16.png',
    'sequence2/17.png',
    'sequence2/18.png',
    'sequence2/19.png',
    'sequence2/20.png',
    'sequence2/21.png',
    'sequence2/22.png',
    'sequence2/23.png',
    'sequence2/24.png',
    'sequence2/25.png',
    'sequence2/26.png'
  ]);
  const refSeqMobile = useRef([
    'Falling-mobile-poster.jpg',
    'Turn-mobile-poster.jpg',
    'sequence1-mobile/1.png',
    'sequence1-mobile/2.png',
    'sequence1-mobile/3.png',
    'sequence1-mobile/4.png',
    'sequence1-mobile/5.png',
    'sequence1-mobile/6.png',
    'sequence1-mobile/7.png',
    'sequence1-mobile/8.png',
    'sequence1-mobile/9.png',
    'sequence1-mobile/10.png',
    'sequence1-mobile/11.png',
    'sequence1-mobile/12.png',
    'sequence1-mobile/13.png',
    'sequence1-mobile/14.png',
    'sequence1-mobile/15.png',
    'sequence1-mobile/16.png',
    'sequence1-mobile/17.png',
    'sequence1-mobile/18.png',
    'sequence1-mobile/19.png',
    'sequence1-mobile/20.png',
    'sequence1-mobile/21.png',
    'sequence1-mobile/22.png',
    'sequence1-mobile/23.png',
    'sequence1-mobile/24.png',
    'sequence1-mobile/25.png',
    'sequence1-mobile/26.png',
    'sequence1-mobile/27.png',
    'sequence1-mobile/28.png',
    'sequence1-mobile/29.png',
    'sequence1-mobile/30.png',
    'sequence1-mobile/31.png',
    'sequence1-mobile/32.png',
    'sequence1-mobile/33.png',
    'sequence1-mobile/34.png',
    'sequence1-mobile/35.png',
    'sequence1-mobile/36.png',
    'sequence1-mobile/37.png',
    'sequence1-mobile/38.png',
    'sequence1-mobile/39.png',
    'sequence1-mobile/40.png',
    'sequence1-mobile/41.png',
    'sequence1-mobile/42.png',
    'sequence1-mobile/43.png',
    'sequence1-mobile/44.png',
    'sequence1-mobile/45.png',
    'sequence1-mobile/46.png',
    'sequence1-mobile/47.png',
    'sequence1-mobile/48.png',
    'sequence1-mobile/49.png',
    'sequence1-mobile/50.png',
    'sequence2-mobile/1.png',
    'sequence2-mobile/2.png',
    'sequence2-mobile/3.png',
    'sequence2-mobile/4.png',
    'sequence2-mobile/5.png',
    'sequence2-mobile/6.png',
    'sequence2-mobile/7.png',
    'sequence2-mobile/8.png',
    'sequence2-mobile/9.png',
    'sequence2-mobile/10.png',
    'sequence2-mobile/11.png',
    'sequence2-mobile/12.png',
    'sequence2-mobile/13.png',
    'sequence2-mobile/14.png',
    'sequence2-mobile/15.png',
    'sequence2-mobile/16.png',
    'sequence2-mobile/17.png',
    'sequence2-mobile/18.png',
    'sequence2-mobile/19.png',
    'sequence2-mobile/20.png',
    'sequence2-mobile/21.png',
    'sequence2-mobile/22.png',
    'sequence2-mobile/23.png',
    'sequence2-mobile/24.png',
    'sequence2-mobile/25.png',
    'sequence2-mobile/26.png'
  ]);
  const refImages = useRef([
    'noise',
    'scull@1x',
    'img0@1x',
    'img1@1x',
    'img2@1x',
    'img3@1x',
    'img4@1x',
    'img5@1x',
    'img6@1x',
    'img7@1x',
    'img8@1x',
    'img9@1x',
    'img4-circle@1x',
    'img4-circle1@1x',
    'img4-circle2@1x',
    'img4-circle3@1x',
  ]);
  const refImages2x = useRef([
    'noise',
    'scull@2x',
    'img0@2x',
    'img1@2x',
    'img2@2x',
    'img3@2x',
    'img4@2x',
    'img5@2x',
    'img6@2x',
    'img7@2x',
    'img8@2x',
    'img9@2x',
    'img4-circle@2x',
    'img4-circle1@2x',
    'img4-circle2@2x',
    'img4-circle3@2x',
  ]);
  const refImagesMobile = useRef([
    'scull-mobile@1x',
    'img0-mobile@1x',
    'img1-mobile@1x',
    'img2@1x',
    'img3-mobile@1x',
    'img-player@1x',
    'img-player-active@1x',
    'img5-mobile@1x',
    'img6-mobile@1x',
    'img7-mobile@1x',
    'img8-mobile@1x',
    'img4-circle-mobile@1x',
  ]);
  const refImagesMobile2x = useRef([
    'scull-mobile@2x',
    'img0-mobile@2x',
    'img1-mobile@2x',
    'img2@2x',
    'img3-mobile@2x',
    'img-player@2x',
    'img-player-active@2x',
    'img5-mobile@2x',
    'img6-mobile@2x',
    'img7-mobile@2x',
    'img8-mobile@2x',
    'img4-circle-mobile@2x',
  ]);
  const refVideos = useRef([
    'Falling.mp4',
    'Turn.mp4'
  ]);
  const refVideosMobile = useRef([
    'Falling-mobile.mp4',
    'Turn-mobile.mp4'
  ]);
  const [counter, setCounter] = useState(0);
  const [fullCounter, setFullCounter] = useState(0);
  useEffect(()=>{
    if(localVideoSrc1 !== null && localVideoSrc2 !== null && !videoLoaded){
      setVideoLoaded(true);
      updateVideo([localVideoSrc1, localVideoSrc2])
    }
  },[localVideoSrc1, localVideoSrc2, updateVideo, videoLoaded]);
  useEffect(() => {
    const images = refImages.current;
    const images2x = refImages2x.current;
    const imagesMobile = refImagesMobile.current;
    const imagesMobile2x = refImagesMobile2x.current;
    const videos = refVideos.current;
    const videosMobile = refVideosMobile.current;
    const seqs = refSeq.current;
    const seqsMobile = refSeqMobile.current;
    let counterArray = [];
    let currentImgArray = [];
    let currentSeqArray = [];
    let currentVideoArray = [];
    function cacheImages(images) {
      const promises = [];
      for (let i = 0; i < images.length; i++) {
        const img = new Image();
        promises.push(
          new Promise((resolve, reject) => {
            img.onload = () => {
              setCounter(prev => prev + 1);
              resolve();
            };
            img.onerror = () => {
              setCounter(prev => prev + 1);
              reject();
            };
            img.src = `${path}/img/${images[i]}.webp`;
          })
        );
      }
      return promises;
    }

    function cacheSeq(images) {
      const promises = [];
      for (let i = 0; i < images.length; i++) {
        const img = new Image();
        promises.push(
          new Promise((resolve, reject) => {
            img.onload = () => {
              setCounter(prev => prev + 1);
              resolve();
            };
            img.onerror = () => {
              setCounter(prev => prev + 1);
              reject();
              // console.log(img);
            };
            setTimeout(() => {

              img.src = `${path}/media/${images[i]}`;
            }, 20 * i);
          })
        );
      }
      return promises;
    }

    function cacheMedia(scrArray) {
      const promises = [];
      for (let i = 0; i < scrArray.length; i++) {
        // const img = new Image();
        promises.push(
          new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `${path}/media/${scrArray[i]}`, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {
              // Если ответ получен успешно, добавляем загруженное видео в атрибут src элемента video
              if (xhr.status === 200) {
                const videoBlob = new Blob([this.response], { type: 'video/mp4' });
                if(i===0){
                  setLocalVideoSrc1(URL.createObjectURL(videoBlob));
                }
                if(i===1){
                  setLocalVideoSrc2(URL.createObjectURL(videoBlob));
                }
                setCounter(prev => prev + 1);
                resolve();
              }
            };
            xhr.send();
          })
        );
      }
      return promises;
    }

    if (window.innerWidth > 767) {
      currentImgArray = window.devicePixelRatio > 1 ? images2x : images;
      currentSeqArray = seqs;
      currentVideoArray = videos;
    } else {
      currentImgArray = window.devicePixelRatio > 1 ? imagesMobile2x : imagesMobile2x;
      currentSeqArray = seqsMobile;
      currentVideoArray = videosMobile;
    }
    Promise.all(cacheImages(currentImgArray))
      .then(() => {
        // console.log('All images preloaded successfully!');
      })
      .catch((error) => {
        console.error('Error preloading images:', error);
        console.log(error);
      });
    Promise.all(cacheSeq(currentSeqArray))
      .then(() => {
        // console.log('All sequences preloaded successfully!');
      })
      .catch((error) => {
        console.error('Error preloading sequences:', error);
        console.log(error);
      });
    Promise.all(cacheMedia(currentVideoArray))
      .then((e) => {
        // console.log('All videos preloaded successfully!');
      })
      .catch((error) => {
        console.error('Error preloading videos:', error);
        console.log(error);
      });
      
    counterArray = [...currentVideoArray, ...currentImgArray, ...currentSeqArray]
    setFullCounter(counterArray.length);
  }, [path]);
  useEffect(() => {

    if (counter !== 0 && counter === fullCounter) {
      updateLoaded(true);
    }
    if (counter !== 0 && counter <= fullCounter) {
      setPercent(Math.floor(counter / fullCounter * 100));
    }
  }, [counter, fullCounter, updateLoaded]);
  useEffect(() => {
    if (isLoaded && !hide) {
      setTimeout(() => {
        refWrap.current.style.opacity = 0;
      }, 2000);
      setTimeout(() => {
        setHide(true);
      }, 2600);
    }
  }, [hide, isLoaded]);
  if (hide) {
    return null;
  }
  return (
    <>
      <div className={styles.wrap} ref={refWrap}>
        <div className={styles.header}>
          <div className={styles.cell}>
            <div className={styles.logo}>
              <svg width="60" height="55" viewBox="0 0 60 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 14.5197C0 6.33773 12.6733 0 30.0009 0C47.3267 0 60 6.33773 60 14.5197C60 30.8836 41.2779 55 30.0009 55C18.7239 55 0 30.8836 0 14.5197ZM46.0246 9.80547L44.4827 19.0692C44.4576 19.222 44.3208 19.3375 44.1575 19.3426L35.8739 19.6143C35.7264 19.6194 35.5985 19.7128 35.5577 19.847L32.6673 29.1209C32.6016 29.3349 32.7757 29.5488 33.0102 29.5386L37.146 29.3569C37.4228 29.3433 37.5989 29.632 37.4512 29.8545L26.2881 46.5987C26.1531 46.8008 25.8919 46.7855 25.7516 46.6498C25.6788 46.5803 25.6379 46.4766 25.6663 46.3559L28.1889 35.3076C28.2404 35.0781 28.0415 34.8659 27.7945 34.8913L24.048 35.2906C23.8223 35.3142 23.6358 35.1275 23.6714 34.917L26.1193 20.3445C26.1548 20.1306 25.9754 19.9387 25.748 19.9454L16.7929 20.2393C16.5779 20.246 16.4109 20.0643 16.4429 19.864L17.9849 10.6002C18.0098 10.4457 18.1465 10.3302 18.31 10.3251L45.6746 9.43017C45.8896 9.42335 46.0584 9.60337 46.0246 9.80547Z" fill="#48CF00" />
              </svg>
            </div>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.icon}>
            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.0769 33.6154V23C7.0769 18.7769 8.75451 14.7268 11.7407 11.7407C14.7268 8.75451 18.7769 7.0769 23 7.0769C27.223 7.0769 31.2731 8.75451 34.2593 11.7407C37.2455 14.7268 38.9231 18.7769 38.9231 23V33.6154" stroke="#48CF00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M38.9231 35.3846C38.9231 36.3231 38.5503 37.2231 37.8867 37.8867C37.2231 38.5503 36.3231 38.9231 35.3846 38.9231H33.6154C32.6769 38.9231 31.7769 38.5503 31.1133 37.8867C30.4497 37.2231 30.0769 36.3231 30.0769 35.3846V30.0769C30.0769 29.1385 30.4497 28.2384 31.1133 27.5748C31.7769 26.9113 32.6769 26.5385 33.6154 26.5385H38.9231V35.3846ZM7.0769 35.3846C7.0769 36.3231 7.44971 37.2231 8.1133 37.8867C8.77689 38.5503 9.67691 38.9231 10.6154 38.9231H12.3846C13.3231 38.9231 14.2231 38.5503 14.8867 37.8867C15.5503 37.2231 15.9231 36.3231 15.9231 35.3846V30.0769C15.9231 29.1385 15.5503 28.2384 14.8867 27.5748C14.2231 26.9113 13.3231 26.5385 12.3846 26.5385H7.0769V35.3846Z" stroke="#48CF00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className={styles.title}>Continue with sound</div>
          <div className={styles.text}>We recommend to wear headphones for a better listening experience</div>
        </div>
        <div className={styles.percent}>{percent}%</div>
        <div className={styles.noise} style={{backgroundImage: `url(${path}/img/noise.webp)`}}>
        {/* <picture>
              <source media="screen and (max-width: 767px)" srcSet={`${path}/img/placeholder.webp`} />
              <img src={`${path}/img/noise.webp`} alt="" />
            </picture> */}
        </div>
      </div>
    </>
  );

}

export default Preloader;
